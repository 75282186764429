import { Route, Switch } from 'react-router-dom'
import CellTypes from './CellTypes'
import Chromosome from './Chromosome/Chromosome'
import Gene from './Gene'
import Genome from './Genome'
import Genomes from './Genomes'
import TissueTypes from './TissueTypes'
import Welcome from './Welcome'
import './Genomics.css'
import BodyView from './Body3d/BodyView'
import About from './About/About'

const Genomics = () => {
    return (
    <div className="root">
        <div>
            <Switch>
                <Route exact path="/">
                    <Welcome/>
                </Route>
                <Route exact path="/genomes">
                    <Genomes/>
                </Route>
                <Route exact path="/genomes/:genomeId">
                    <Genome/>
                </Route>
                <Route exact path="/cellTypes">
                    <CellTypes/>
                </Route>
                <Route exact path="/tissueTypes">
                    <TissueTypes/>
                </Route>
                <Route exact path="/bodyView">
                    <BodyView/>
                </Route>
                <Route exact path="/genomes/:genomeId/chromosomes/:chromosomeId">
                    <Chromosome/>
                </Route>
                <Route exact path="/genomes/:genomeId/chromosomes/:chromosomeId/gene/:geneId">
                    <Gene/>
                </Route>
                <Route exact path="/about">
                    <About/>
                </Route>
            </Switch>
        </div>
    </div>)
}

export default Genomics