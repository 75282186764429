import './BodyView.css'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, PerspectiveCamera, useGLTF } from '@react-three/drei'
import ClipLoader from "react-spinners/ClipLoader"
import { PARTS_URL, PART_RELATIONS_URL, ELEMENTS_URL } from '../Api'
import { TreeView } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import TreeItem, {
  TreeItemProps,
  useTreeItem,
  TreeItemContentProps,
} from '@mui/lab/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

const CustomContent = React.forwardRef(function CustomContent(
  props,
  ref,
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (
    event
  ) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (
    event
  ) => {
    props.onClick(nodeId)
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
    </div>
  );
});

const CustomTreeItem = (props) => {
  return (
  <TreeItem ContentComponent={CustomContent} {...props} />
)}

function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.x += 0.01))
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}>
      <boxGeometry args={[0.1, 0.1, 0.1]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}

function GltfModel({ ...props }) {
  const group = useRef()
  const fileName = `/glb/${props.fileName}.glb`
  // eslint-disable-next-line
  const { nodes, materials } = useGLTF(fileName)
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.grp1.geometry} material={nodes.grp1.material} />
    </group>
  )
}

const GltfModels = React.memo(props => {
  const fileNames = props.fileNames
  const models = fileNames.map((fileName) => {
      return <GltfModel key={fileName} fileName={fileName}/>
  })
  return models
})

const addChildren = (parentId, children, relations) => {
  relations.forEach(element => {
    const parent = element.parent
    const child = element.child
    if (parent === parentId) {
      const childChildren = {}
      children[child] = childChildren
      addChildren(child, childChildren, relations)
    }
  })
}

const createRelationsTree = (parts, relations) => {
  const parents = {}
  const children = []
  relations.forEach(element => {
    const parent = element.parent
    const child = element.child
    children.push(child)

    if (!(parent in parents)) {
      parents[parent] = []
    }
    parents[parent].push(child)
  })

  const rootNodes = []
  for (const [key, value] of Object.entries(parents)) {
    if (!children.includes(key)) {
      rootNodes.push(key)
    }
  }
  
  const rootNode = rootNodes[0] // there appeared to be only one
  const tree = {}
  addChildren(rootNode, tree, relations)

  // need to add top level node
  const completeTree = {
    [rootNode]: tree
  }

  return completeTree
}


const mapElementsByConceptId = (elements) => {
  const mapped = {}
  elements.forEach(element => {
    const conceptId = element.part
    if (!(conceptId in mapped)) {
      mapped[conceptId] = []
    }
    mapped[conceptId].push(element.elementFile)
  })
  return mapped
}


const getSelectedFileNames = (selectedElements, elements) => {
  if (elements === undefined) return []
  else {
    const mappedElements = mapElementsByConceptId(elements)
    const fileNames = selectedElements.map(conceptId => {
      return mappedElements[conceptId]
    }).flat()
    return fileNames
  }
}

function Three(props) {

  // const [fileNames, setFileNames] = useState()
  // useEffect(()=>{
  //   fetch("./files.txt").then((value)=> {
  //     value.body.getReader().read().then((reead)=>{
  //       const text = new TextDecoder().decode(reead.value)
  //       const words = text.split("\n")
  //       setFileNames(words.filter(word => word !== ""))
  //     })
  //   })
  // }, [])

  const orbitControlsRef = useRef(null)

  const selectedElements = props.selectedElements
  const selectedFilenames = getSelectedFileNames(selectedElements, props.elements)

  return (
    <>
      <Suspense fallback={null}>
        <OrbitControls ref={orbitControlsRef}/>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        {/* <Plane position={[0, 0, -5]} rotation={[0, 0, 0]} args={[2,2]}>
          <meshBasicMaterial color="red" />
          </Plane> */}
        {/* <Box position={[-1.2, 0, 0]} /> */}
        <Box position={[2, 0, 0]} />

        <PerspectiveCamera makeDefault position={[0, 1, 5]}/>
        {/* <mesh rotation={[5, 0, 0]} position={[10, 10, 10]}> */}
        <mesh rotation={[-1.3, 0, 0]} position={[0, -0.5, -0]} scale={[0.0005,0.0005,0.0005]}>
            {/* <GltfModels fileNames={fileNames}/>   */}
          <GltfModels fileNames={selectedFilenames}/>
          {/* <GltfModel fileName={"FJ3329"}/>
          <GltfModel fileName={"FJ1253"}/>
          <GltfModel fileName={"FJ3307"}/> */}
        </mesh>
      </Suspense>
    </>
  )
}

const createChildrenEntries = (entries, elements, onItemClicked) => {
  const elementsList = []
  for (const [parentId, children] of Object.entries(entries)) {
    const childrenEntries = createChildrenEntries(children, elements, onItemClicked)
    const element = elements[parentId]
    elementsList.push(
      <CustomTreeItem key={parentId} nodeId={parentId} label={element.name} onClick={onItemClicked}>
        {childrenEntries}
      </CustomTreeItem>
    )
  }
  return elementsList
}

const PartsTreeView = props => {
  const tree = props.tree
  if (tree === undefined || props.elements === undefined) { return (<></>)}
  const elements = mapByConceptId(props.elements)
  
  return (
    <>
      <TreeView
        aria-label="body parts navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ height: 600, flexGrow: 1, maxWidth: 1200, overflowY: 'auto' }}
      >
        {createChildrenEntries(tree, elements, props.onItemClicked)}
      </TreeView>
    </>
  )
}

const mapByConceptId = parts => {
  const mapped = {}
  parts.forEach(part => {
    mapped[part.conceptId] = part
  })
  return mapped
}

const BodyView = () => {
  const [parts, setParts] = useState()
  const [relations, setRelations] = useState()
  const [elements, setElements] = useState()

  const [relationsTree, setRelationsTree] = useState()
  const [selectedElements, setSelectedElements] = useState([])


  useEffect(()=>{
    loadParts()
    loadRelations()
    loadElements()
  }, [])

  const loadElements = () => {
    fetch(ELEMENTS_URL)
    .then(response => response.json())
    .then(responseJson => {
        setElements(responseJson)
    })
  }

  const loadParts = () => {
    fetch(PARTS_URL)
    .then(response => response.json())
    .then(responseJson => {
        setParts(responseJson)
    })
  }

  const loadRelations = () => {
    fetch(PART_RELATIONS_URL)
    .then(response => response.json())
    .then(responseJson => {
        setRelations(responseJson)
    })
  }

  useEffect(()=>{
    if (parts !== undefined && relations !== undefined && elements !== undefined) {
      // console.log("loaded all!")
      // console.log(parts)
      // console.log(relations)
      // console.log(elements)

      // const relationsTree = createRelationsTree(parts, relations)
      setRelationsTree(createRelationsTree(parts, relations))
    }
  },[parts, relations, elements])

  const onItemClicked = item => {
    if (selectedElements.includes(item)) {
      setSelectedElements(selectedElements.filter(it => it !== item))
    } else {
      setSelectedElements(selectedElements.concat([item]))
    }
  }

  // useEffect(()=>{
  //   console.log("selected these:")
  //   console.log(selectedElements)
  // }, [selectedElements])

  return (
    <div id="bodyview" className="bodyView">
      {/* <ClipLoader/> Loading may take a long time, pleace be patient */}
      Select body parts below the blue area. It might load for significant time if you select the top level element.
      <p/>
      <div className="canvasArea">
        <Canvas>
          <Three selectedElements={selectedElements} elements={elements}/>
        </Canvas>
      </div>
      <p/>
      3D models copyright notice:<br/>
      BodyParts3D, Copyright© The Database Center for Life Science licensed by CC Attribution-Share Alike 2.1 Japan
      <p/>
      Click on the name to select/unselect. Reload page to reset
      <PartsTreeView tree={relationsTree} elements={parts} onItemClicked={onItemClicked}/>
    </div>
  )
}

export default BodyView
