import { useCallback, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { tableIcons } from '../TableIcons'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import "./Chromosome.css"
import { getTableColumns, getTableData, loadGenes, loadChromosome } from './ChromosomeViewModel'

const Chromosome = () => {
    const { genomeId, chromosomeId } = useParams()
    const [chromosome, setChromosome] = useState(undefined)
    const [genes, setGenes] = useState([])
    const [continueHelper, setContinueHelper] = useState(undefined)
    const [dead, setDead] = useState(false)

    const updateGenes = useCallback((newGenes, onSaved) => {
        const continueObject = {
            "newGenes": newGenes,
            "onSaved": onSaved,
        }
        setContinueHelper(continueObject)
    }, [])


    useEffect(()=>{
        loadChromosome(chromosomeId, setChromosome)
        loadGenes(chromosomeId, updateGenes)
    },[chromosomeId, updateGenes])

    useEffect(()=> {
        if (continueHelper !== undefined) {
            const newGenes = continueHelper["newGenes"]
            const onSaved = continueHelper["onSaved"]
            const updatedGenes = [...genes, ...newGenes]
            setContinueHelper(undefined)
            setGenes(updatedGenes)

            if (!dead) {
                onSaved()
            }
        }
    }, [continueHelper, genes, dead])

    useEffect(()=>{
        return () => {
            setDead(true)
        }
    },[])

    let content = undefined
    if(chromosome !== undefined) {
        content = (<div>
            <p>Length (bp): {chromosome.sequenceLength}</p>
            <p>Genes: {genes.length}</p>
        </div>)
    }

    const tableColumns = getTableColumns()
    const tableData = getTableData(genes, chromosomeId, genomeId)

    return (
        <div className="main">
            <h1>Chromosome {chromosome ? chromosome.name : ""}</h1>
            <div className="info">
                {content}
                <Link to={`/genomes/${genomeId}`}>Back</Link>
            </div>
            <MaterialTable
                columns={tableColumns}
                data={tableData}
                title={`Genes in ${genomeId}`}
                icons={tableIcons}
                options={{
                    pageSize: 20
                }}
        />)
        </div>
    )
}

export default Chromosome