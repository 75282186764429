const API_HOST = process.env.REACT_APP_GENOME_API_HOST

export const SEQUENCES_URL = `${API_HOST}/api/genomes/sequences/`
export const GENES_URL = `${API_HOST}/api/genomes/genes/`
export const CELLTYPES_URL = `${API_HOST}/api/genomes/cellTypes/`
export const GENEBLOBS_URL = `${API_HOST}/api/genomes/geneBlobs/`
export const GENOMES_URL = `${API_HOST}/api/genomes/genomes/`
export const TISSUETYPES_URL = `${API_HOST}/api/genomes/tissueTypes/`
export const PARTS_URL = `${API_HOST}/api/bodyview/isaParts/`
export const PART_RELATIONS_URL = `${API_HOST}/api/bodyview/isaElementInclusionRelations/`
export const ELEMENTS_URL = `${API_HOST}/api/bodyview/isaElements/`
