import { Button, Card } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import "./Genomes.css"
import { GENOMES_URL } from './Api'

const handleClick = (history, path) => {
    history.push(path)
}

const AvailableGenomes = (genomes) => {
    const history = useHistory()
    const elements = []
    genomes.forEach(g => {
        const path = `/genomes/${g.id}`
        const name = g['name']
        const comment = g['comment']
        const div = (
            <Card key={name} style={{width: '18rem'}}>
                <Card.Body>
                    <Card.Title>{name}</Card.Title>
                    <Card.Text>
                        {comment}
                    </Card.Text>
                    <Button onClick={()=>{handleClick(history, path)}}>View</Button>
                </Card.Body>
            </Card>)
        elements.push(div)
    })
    return (
        <div>
            <h1>Human genomes</h1>
            <Link to="/">Home</Link>
            <p>The human genome consists of approximately 50.000 genes.</p>
            <p>These genes are spread out over chromosomes, of which humans have 23 pairs.</p>
            <p>You'll find in the list that there are more entries than that, and that is because it is very difficult to completely map the human genome.
                Therefore, there are a few entries left called like "unlocalized scaffold", which is a sequence that the guys haven't been able figure out where
                belongs.
            </p>
            <p>The actual chromosomes are the ones called just 1, 2, 3, 4.. etc, up to 22, and then there are the two special ones X and Y.</p>
            <p>If you look at the genes in X and Y you'll see that there are many genes related to gender and sexy things, which is how we know that these
                chromosomes are responsible for determining the gender of the individual carrying the chromosomes. Humans all have two copies of each chromosome,
                each copy slightly varying from the other, because they originate from the two individual parents. With X and Y it's a little different, because,
                it looks like while female humans have two copies of X, just like normally with the other chromosomes, male humans have just one copy of X and one
                copy of Y! Somehow X and Y are able to make a pair for males, although they're not the same chromosome.
            </p>
            <p>Without further ado, here you can browse and see information about each individual chromosome, and which genes they contain.</p>
            <div className="genomes">{elements}</div>
        </div>)
}

const Genomes = () => {
    const [genomes, setGenomes] = useState([])

    useEffect(() => {
        fetch(GENOMES_URL)
        .then(response => response.json())
        .then(responseJson => {
            setGenomes(responseJson)
        })
        .catch(err=>{
            alert("error?")
            console.error(err)
        })
    },[])

    let content = AvailableGenomes(genomes)
    return (<div>
        <div className="genomeBase">
            {content}
        </div>
    </div>)
}
export default Genomes