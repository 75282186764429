import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import "./Gene.css"
import { GENEBLOBS_URL, GENES_URL} from './Api'

const Gene = () => {
    const { genomeId, chromosomeId, geneId } = useParams()
    const [gene, setGene] = useState(undefined)
    const [geneBlob, setGeneBlob] = useState(undefined)

    const loadGene = (geneId) => {
        fetch(GENES_URL + `${geneId}`)
        .then(response => response.json())
        .then(response => {
            setGene(response)
        })
    }

    const loadGeneBlob = (geneId) => {
        fetch(GENEBLOBS_URL + `?geneId=${geneId}`)
        .then(response => response.json())
        .then(response => {
            console.log(response)
            setGeneBlob(response[0])
        })
    }

    useEffect(()=>{
        loadGene(geneId)
    },[geneId])

    useEffect(()=>{
        if(gene !== undefined) {
            loadGeneBlob(gene.geneId)
        }
    },[gene])

    let content = undefined
    if(gene !== undefined) {
        content = (<div>
            <p>Name: {gene.name}</p>
            <p>Discontinued: {gene.discontinued ? "yes" : "no"}</p>
            <p>Description: {gene.description}</p>
            <p>Chromosome: {gene.chromosome}</p>
            <p>Genetic source: {gene.geneticSource}</p>
            <p>Map Location: {gene.mapLocation}</p>
            <p>Nomenclature Symbol: {gene.nomenclatureName}</p>
            <p>Nomenclature Name: {gene.nomenclatureName}</p>
            <p>Nomenclature Status: {gene.nomenclatureStatus}</p>
            <p>Gene Weight: {gene.geneWeight}</p>
            <p>Summary: {gene.summary}</p>
            <p>Chromosome Start: {gene.chromosomeStart}</p>
        </div>)
        console.log(gene)
    }

    const dataString = geneBlob ? geneBlob.data : ""
    const cleaned = dataString.replace("\\", "")
    let ds = ""
    let blobData = []
    if(geneBlob) {
        const parsed = JSON.parse(cleaned)
        ds = parsed.DocumentSummary[0]

        const locationHist = ds.LocationHist
        const organism = ds.Organism
        const dbBuild = parsed.DbBuild
        const genomicInfo = ds.GenomicInfo
        const mim = ds.Mim
        const chromosomeSort = ds.ChrSort
        blobData = (<div>
            <p>Chromosome Sort: {chromosomeSort}</p>
            <p>Other aliases: {ds.OtherAliases}</p>
            <p>Other designations: {ds.OtherDesignations}</p>
            <p>Organism: {JSON.stringify(organism)}</p>
            <p>Genomic Info: {JSON.stringify(genomicInfo)}</p>
            <p>Mim: {JSON.stringify(mim)}</p>
            <p>Location Hist: {JSON.stringify(locationHist)}</p>
            <p>DB Build: {dbBuild}</p>
        </div>)
    }
    
    return (
        <div className="main">
            <h1>Gene {gene ? gene.name : ""}</h1>
            <div className="info">
                {content}
                {blobData}
                <Link to={`/genomes/${genomeId}/chromosomes/${chromosomeId}`}>Back</Link>
            </div>
        </div>
    )
}

export default Gene