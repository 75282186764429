import './App.css'
import Genomics from './Genome/Genomics'
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <div>
        <Genomics/>
      </div>
    </BrowserRouter>
  )
}

export default App
