import { Link } from 'react-router-dom'

const Welcome = () => {
    return (
        <div>
            <h1>Anatview.com</h1>
                Here you can find information about various parts of the human anatomy, in an interactive format.
                <p/>
                <Link to="/genomes">Genomes</Link>
                <p/>
                <Link to="/bodyView">Body view (alpha version 0.0.1)</Link>
                <p/>
                <Link to="/cellTypes">Cell Types</Link>
                <p/>
                <Link to="/tissueTypes">Tissue Types</Link>
                <p/>
                <Link to="/about">About</Link>
        </div>
    )
}

export default Welcome