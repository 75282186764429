import { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { tableIcons } from './TableIcons'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { SEQUENCES_URL } from './Api'
import './Genome.css'

const getTableColumns = () => {
    return [
        {title: "Name", field: "name"},
        {title: "UCSC Name", field: "ucscStyleName"},
        {title: "Role", field: "sequenceRole"},
        {title: "Assigned Molecule", field: "assignedMolecule"},
        {title: "Assigned Type", field: "assignedType"},
        {title: "Assembly Unit", field: "assemblyUnit"},
        {title: "Refsec Accn", field: "refseqAccn"},
        {title: "Relationship", field: "relationship"},
        {title: "Genbank Accn", field: "genbankAccn"},
        {title: "Length", field: "length"},
        {title: "View", field: "id", render: rowData => <Link to={`/genomes/${rowData.genomeId}/chromosomes/${rowData.id}`}>Details</Link>}
    ]
}

const getTableData = (sequences, genomeId) => {
    const rows = sequences.map((s) => {
        return (
            {
                name: s.name,
                sequenceRole: s.sequenceRole,
                assignedMolecule: s.assignedMolecule,
                assignedType: s.assignedType,
                assemblyUnit: s.assemblyUnit,
                ucscStyleName: s.ucscStyleName,
                refseqAccn: s.refseqAccessionNumber,
                relationship: s.relationship,
                genbankAccn: s.genbankAccessionNumber,
                length: s.sequenceLength,
                id: s.id,
                genomeId: genomeId
            }
        )
    })
    return rows
}

const Genome = () => {
    const { genomeId } = useParams()
    const [sequences, setSequences] = useState([])

    const loadSequences = (id) => {
        fetch(`${SEQUENCES_URL}?genome=${id}`)
        .then(response => response.json())
        .then(responseJson => {
            setSequences(responseJson)
        })
    }

    useEffect(()=>{
        loadSequences(genomeId)
    },[genomeId])

    const tableColumns = getTableColumns()
    const tableData = getTableData(sequences, genomeId)

    return (<div className="genome">
        <Link to="/genomes">Back</Link>
        <MaterialTable
            columns={tableColumns}
            data={tableData}
            title={`Chromosomes in ${genomeId}`}
            icons={tableIcons}
            options={{
                pageSize: 20
            }}
        />)
    </div>)
}

export default Genome
