import { useEffect, useState } from 'react'
import MaterialTable from "material-table"
import { tableIcons } from './TableIcons'
import { CELLTYPES_URL } from './Api'
import { Link } from 'react-router-dom'

const getTableColumns = () => {
    return [
        {title: "Name", field: "name"},
        {title: "Description", field: "description"}
    ]
}

const getTableData = (cellTypes) => {
    const rows = cellTypes.map((s) => {
        return (
            {
                name: s.name,
                description: s.description,
                id: s.id
            }
        )
    })
    return rows
}

const CellTypes = () => {
    const [cellTypes, setCellTypes] = useState([])

    useEffect(()=>{
        fetch(CELLTYPES_URL)
        .then(response => response.json())
        .then(responseJson => {
            setCellTypes(responseJson)
        })
    }, [])

    const tableColumns = getTableColumns()
    const tableData = getTableData(cellTypes)

    return (
    <div>
        This particular page is still very experimental. Check out the genomes page instead, and please come back later.
        <p/>
        <Link to="/">Back</Link>
        <MaterialTable 
            columns={tableColumns}
            data={tableData}
            title={"Human cell types"}
            icons={tableIcons}
            options={{
                pageSize: 20
            }}
        />
    </div>)
}

export default CellTypes
