import { Link } from 'react-router-dom'

const About = () => {
    return (
        <div>
            <h1>Anatview.com</h1>
                <Link to="/">Back</Link>
                <p/>
                This is a web app developed by the company Modularity AS (<a href="https://modularity.no">modularity.no</a>)
                <p/>
                It's a work in progress exploratory project.
                <p/>
                Read more about it in this <a href="https://www.simernes.com/?p=608">blog post</a>.
                <p/>
        </div>
    )
}

export default About