import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { SEQUENCES_URL, GENES_URL} from '../Api'


export const loadChromosome = (chromosomeId, setChromosome) => {
    fetch(SEQUENCES_URL + `${chromosomeId}`)
        .then(response => response.json())
        .then(responseJson => {
            setChromosome(responseJson)
    })
}

export const loadGenes = (chromosomeId, updateGenes) => {
    fetch(`${GENES_URL}?chromosome=${chromosomeId}`)
        .then(response => response.json())
        .then(response => {
            const genes = response.results
            const continueCallback = (updatedGenes) => {
                loadNextGenes(response, updateGenes, updatedGenes)
            }
            updateGenes(genes, continueCallback)
    })
}

const loadNextGenes = (response, updateGenes) => {
    const next = response.next
    if(next !== undefined) {
        fetch(next)
            .then(response => response.json())
            .then(response => {
                    const genes = response.results
                    const continueCallback = () => {
                        loadNextGenes(response, updateGenes)
                    }
                    updateGenes(genes, continueCallback)
                }
            )
    }
}

export const getTableColumns = () => {
    return [
        {title: "Name", field: "name"},
        {title: "Description", field: "description"},
        {title: "Map Location", field: "mapLocation"},
        {title: "Gene Weight", field: "geneWeight"},
        {title: "Discontinued", field: "discontinued"},
        {title: "Genetic Source", field: "geneticSource"},
        {title: "Chromosome Start", field: "chromosomeStart"},
        {title: "View", field: "id", render: rowData => <Link to={`/genomes/${rowData.genomeId}/chromosomes/${rowData.chromosomeId}/gene/${rowData.geneId}`}>Details</Link>}
    ]
}

export const getTableData = (genes, chromosomeId, genomeId) => {
    const rows = genes.map((g) => {
        return (
            {
                name: g.name,
                geneId: g.id,
                description: g.description,
                mapLocation: g.mapLocation,
                geneWeight: g.geneWeight,
                discontinued: g.discontinued,
                geneticSource: g.geneticSource,
                chromosomeStart: g.chromosomeStart,
                chromosomeId: chromosomeId,
                genomeId: genomeId
            }
        )
    })
    return rows
}